<template>
  <div class="column">
    <table id="depositstable" class="table is-hoverable is-hidden-touch">
      <thead>
        <tr>
          <th>#</th>
          <th>Date</th>
          <th>Asset</th>
          <th>Direction</th>
          <!-- <th>Position Size</th> -->
          <th>Risk %</th>
          <th>Realized %</th>
          <th>Profit in R</th>
          <th>Profit</th>
          <th>Balance</th>
          <th>Setup</th>
          <th>Result</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td></td>
          <td>
            <input
              v-model="newTrade.timestamp"
              type="datetime-local"
              class="input"
            />
          </td>
          <td>
            <input
              v-model="newTrade.asset"
              class="input is-primary"
              type="text"
            />
          </td>
          <td>
            <div class="select">
              <select v-model="newTrade.direction">
                <option value="long">long</option>
                <option value="short">short</option>
              </select>
            </div>
          </td>
          <!-- <td>
          <input v-model.number="newTrade.size" class="input is-primary" type="number">
        </td> -->
          <td>
            <input
              v-model.number="newTrade.risk_percent"
              class="input is-primary m-0"
              type="number"
            />
          </td>
          <td>
            <input
              v-model.number="newTrade.realized_percent"
              class="input is-primary"
              type="number"
            />
          </td>
          <td>
            <input
              v-model.number="newTrade.profit_r"
              class="input is-primary"
              type="number"
            />
          </td>
          <td>
            <input
              v-model.number="newTrade.profit"
              class="input is-primary"
              type="number"
            />
          </td>
          <td>
            <input
              v-model.number="newTrade.balance"
              class="input is-primary"
              type="number"
            />
          </td>
          <td>
            <input
              v-model="newTrade.before"
              class="input is-primary"
              type="text"
              placeholder="chart link"
            />
          </td>
          <td>
            <input
              v-model="newTrade.after"
              class="input is-primary"
              type="text"
              placeholder="chart link"
            />
          </td>
          <td class="">
            <button
              @click="addTrade()"
              :disabled="!isTradeValid(newTrade)"
              class="button is-primary is-fullwidth"
            >
              Add
            </button>
          </td>
        </tr>
        <tr v-for="(trade, i) in trades" :key="trade.id">
          <template v-if="isEditTrade && editTrade.id === trade.id">
            <td>
              <span class="">{{ i + 1 }}</span>
            </td>
            <td>
              <input
                v-model="editTrade.timestamp"
                class="input"
                type="datetime-local"
              />
            </td>
            <td>
              <input
                v-model="editTrade.asset"
                class="input is-primary"
                type="text"
              />
            </td>
            <td>
              <div class="select">
                <select v-model="editTrade.direction">
                  <option value="long">long</option>
                  <option value="short">short</option>
                </select>
              </div>
            </td>
            <!-- <td>
            <input v-model.number="editTrade.size" class="input is-primary" type="number">
          </td> -->
            <td>
              <input
                v-model.number="editTrade.risk_percent"
                class="input is-primary"
                type="number"
              />
            </td>
            <td>
              <input
                v-model.number="editTrade.realized_percent"
                class="input is-primary"
                type="number"
              />
            </td>
            <td>
              <input
                v-model.number="editTrade.profit_r"
                class="input is-primary"
                type="number"
              />
            </td>
            <td>
              <input
                v-model.number="editTrade.profit"
                class="input is-primary"
                type="number"
              />
            </td>
            <td>
              <input
                v-model.number="editTrade.balance"
                class="input is-primary"
                type="number"
              />
            </td>
            <td>
              <input
                v-model="editTrade.before"
                class="input is-primary"
                type="text"
              />
            </td>
            <td>
              <input
                v-model="editTrade.after"
                class="input is-primary"
                type="text"
              />
            </td>
            <td>
              <div class="field is-grouped">
                <p class="control">
                  <button
                    :disabled="!isTradeValid(editTrade)"
                    class="button is-primary"
                    @click="updateTrade()"
                  >
                    Update
                  </button>
                </p>
                <p class="control">
                  <button
                    class="button is-danger is-light"
                    @click="cancelEditTrade()"
                  >
                    Cancel
                  </button>
                </p>
              </div>
            </td>
          </template>
          <template v-else>
            <td
              :class="{
                'has-background-primary-light': trade.profit_r >= 0,
                'has-background-danger-light': trade.profit_r < 0,
              }"
            >
              <span>{{ i + 1 }}</span>
            </td>
            <td>
              <span class="">{{ trade.timestamp | date }}</span>
            </td>
            <td>
              <span class="has-text-weight-semibold">{{ trade.asset }}</span>
            </td>
            <td>
              <span
                v-if="trade.direction === 'long'"
                class="has-text-success has-text-weight-bold"
                >long</span
              >
              <span v-else class="has-text-danger has-text-weight-bold"
                >short</span
              >
            </td>
            <!-- <td>
            <span class="">{{ trade.size }}</span>
          </td> -->
            <td>
              <span class="">{{ trade.risk_percent }}%</span>
            </td>
            <td>
              <span
                v-if="trade.realized_percent > 0"
                class="is-size-5 has-text-success has-text-weight-bold"
                >+</span
              >
              <span v-if="trade.realized_percent > 0" class=""
                >{{ Math.abs(trade.realized_percent) }}%</span
              >
              <span
                v-if="trade.realized_percent < 0"
                class="is-size-5 has-text-danger has-text-weight-bold"
                >-</span
              >
              <span v-if="trade.realized_percent < 0" class=""
                >{{ Math.abs(trade.realized_percent) }}%</span
              >
              <span v-if="trade.realized_percent === 0" class="">BE</span>
            </td>
            <td>
              <span
                v-if="trade.profit_r > 0"
                class="is-size-5 has-text-success has-text-weight-bold"
                >+</span
              >
              <span v-if="trade.profit_r > 0" class=""
                >{{ Math.abs(trade.profit_r) }}R</span
              >
              <span
                v-if="trade.profit_r < 0"
                class="is-size-5 has-text-danger has-text-weight-bold"
                >-</span
              >
              <span v-if="trade.profit_r < 0" class=""
                >{{ Math.abs(trade.profit_r) }}R</span
              >
              <span v-if="trade.profit_r === 0" class="">BE</span>
            </td>
            <td>
              <span
                v-if="trade.profit > 0"
                class="is-size-5 has-text-success has-text-weight-bold"
                >+</span
              >
              <span v-if="trade.profit > 0" class="">{{
                Math.abs(trade.profit)
              }}</span>
              <span
                v-if="trade.profit < 0"
                class="is-size-5 has-text-danger has-text-weight-bold"
                >-</span
              >
              <span v-if="trade.profit < 0" class="">{{
                Math.abs(trade.profit)
              }}</span>
              <span v-if="trade.profit === 0" class="">BE</span>
            </td>
            <td>
              <span class="">{{ trade.balance }}</span>
            </td>
            <td>
              <a
                :disabled="!trade.before"
                :href="trade.before"
                class="button is-link is-small is-light"
                target="_blank"
                >View</a
              >
            </td>
            <td>
              <a
                :disabled="!trade.after"
                :href="trade.after"
                class="button is-link is-small is-light"
                target="_blank"
                >View</a
              >
            </td>
            <td>
              <div class="field is-grouped">
                <p class="control">
                  <button
                    class="button is-small is-primary is-light"
                    @click="startEditTrade(trade)"
                  >
                    Edit
                  </button>
                </p>
                <p class="control">
                  <button
                    class="button is-small is-danger is-light"
                    @click="deleteTrade(trade)"
                  >
                    Delete
                  </button>
                </p>
              </div>
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { db } from "@/firebase";

export default {
  name: "DepositsTable",
  props: ["tradeJournal", "trades", "privacyMode"],
  components: {},
  data() {
    return {
      isEditTrade: null,
      newTrade: {
        timestamp: new Date().toISOString().slice(0, 16),
        asset: null,
        direction: "long",
        size: null,
        risk_percent: 1,
        realized_percent: null,
        profit_r: null,
        profit: null,
        balance: null,
        before: null,
        after: null,
      },
      editTrade: {
        id: null,
        timestamp: new Date(),
        asset: null,
        direction: "long",
        size: null,
        risk_percent: 1,
        realized_percent: null,
        profit_r: null,
        profit: null,
        balance: null,
        before: null,
        after: null,
      },
    };
  },
  async created() {},
  filters: {
    date(val) {
      if (val) {
        return val.toDate().toLocaleString();
      } else {
        return val;
      }
    },
  },
  computed: {
    isTradeValidC() {
      if (!this.newTrade.timestamp) {
        return false;
      } else if (!this.newTrade.asset) {
        return false;
      } else if (!this.newTrade.direction) {
        //   return false
        // } else if (!this.newTrade.size) {
        return false;
      } else if (!this.newTrade.risk_percent) {
        return false;
        // } else if (!this.newTrade.realized_percent) {
        // return false
        // } else if (!this.newTrade.profit_r) {
        //   return false
        // } else if (!this.newTrade.profit) {
        //   return false
      } else if (!this.newTrade.balance) {
        return false;
      }
      return true;
    },
  },
  methods: {
    isTradeValid(trade) {
      if (!trade.timestamp) {
        return false;
      } else if (!trade.asset) {
        return false;
      } else if (!trade.direction) {
        //   return false
        // } else if (!trade.size) {
        return false;
      } else if (!trade.risk_percent) {
        return false;
        // } else if (!trade.realized_percent) {
        // return false
        // } else if (!trade.profit_r) {
        //   return false
        // } else if (!trade.profit) {
        //   return false
      } else if (!trade.balance) {
        return false;
      }
      return true;
    },
    async addTrade() {
      console.log(this.newTrade);
      await db
        .collection("users")
        .doc(this.$store.state.userProfile.uid)
        .collection("tradejournals")
        .doc(this.tradeJournal.id)
        .collection("trades")
        .add({
          ...this.newTrade,
          realized_percent: this.newTrade.realized_percent || 0,
          profit_r: this.newTrade.profit_r || 0,
          profit: this.newTrade.profit || 0,
          timestamp: new Date(this.newTrade.timestamp),
        });
    },
    async updateTrade() {
      await db
        .collection("users")
        .doc(this.$store.state.userProfile.uid)
        .collection("tradejournals")
        .doc(this.tradeJournal.id)
        .collection("trades")
        .doc(this.editTrade.id)
        .update({
          ...this.editTrade,
          realized_percent: this.editTrade.realized_percent || 0,
          profit_r: this.editTrade.profit_r || 0,
          profit: this.editTrade.profit || 0,
          timestamp: new Date(this.editTrade.timestamp),
        });
      this.isEditTrade = false;
    },
    async deleteTrade(trade) {
      await db
        .collection("users")
        .doc(this.$store.state.userProfile.uid)
        .collection("tradejournals")
        .doc(this.tradeJournal.id)
        .collection("trades")
        .doc(trade.id)
        .delete();
    },
    startEditTrade(trade) {
      console.log(trade);
      this.editTrade = {
        ...trade,
        id: trade.id,
      };
      this.editTrade.timestamp = trade.timestamp
        .toDate()
        .toISOString()
        .slice(0, 16);
      this.isEditTrade = true;
    },
    cancelEditTrade() {
      this.isEditTrade = false;
      this.editTrade = {
        id: null,
        timestamp: new Date(),
        asset: null,
        direction: "long",
        size: 0,
        risk_percent: 1,
        realized_percent: 1,
        profit_r: 1,
        profit: 1,
        balance: null,
        before: null,
        after: null,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
#tradestable {
  background-color: var(--table-background-color);
  color: var(--table-content-color);
}
#tradestable td {
  border: 1px solid;
  border-color: var(--app-background-color);
  &:hover {
    background-color: var(--app-background-color);
    border: 1px solid;
    border-color: var(--table-background-color);
  }
}
.table {
  background-color: var(--app-background-color);
}

#tradestable tr {
  border: 1px solid;
  border-color: var(--app-background-color);
  &:hover {
    background-color: var(--app-background-color);
    border: 1px solid;
    border-color: var(--table-background-color);
  }
}
.has-background-primary-light {
  background-color: var(--positive-trade-result) !important;
}
.has-background-danger-light {
  background-color: var(--negative-trade-result) !important;
}
.input[type="number"] {
  -moz-appearance: textfield;
}
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
</style>
