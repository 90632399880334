<template>
  <div class="column">
    <h4 class="title is-5">Overview</h4>
    <div id="card">
    <table class="table is-narrow">
      <tbody>
      <tr>
        <td>Total Trades</td>
        <td>{{ this.trades.length }}</td>
      </tr>
      <tr>
        <td>Wins</td>
        <td>{{ wins }}</td>
      </tr>
      <tr>
        <td>Losses</td>
        <td>{{ losses }}</td>
      </tr>
      <tr>
        <td>Early Loss</td>
        <td>{{ earlyLoss }}</td>
      </tr>
      <tr>
        <td>BE/Profit</td>
        <td>{{ breakEven }}</td>
      </tr>
      <tr>
        <td>Win %</td>
        <td>{{ winPercent }}%</td>
      </tr>
      <tr>
        <td>R Profit</td>
        <td>{{ rProfit }}</td>
      </tr>
      <tr>
        <td>Account Increase %</td>
        <td>{{ accountIncreasePercent }}%</td>
      </tr>
      <tr>
        <td>Profit</td>
        <td v-if="privacyMode">Hidden</td>
        <td v-else>{{ profit }}</td>
      </tr>
      <tr>
        <td>Account Balance</td>
        <td v-if="privacyMode">Hidden</td>
        <td v-else>{{ accountBalance }}</td>
      </tr>
      </tbody>
    </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "TradesOverviewStats",
  props: ['trades', 'privacyMode'],
  components: {
  },
  data() {
    return {
    }
  },
  async created() {
  },
  filters: {
  },
  computed: {
    wins() {
      return this.trades.filter(x => x.profit_r > 0.5).length
    },
    losses() {
      return this.trades.filter(x => x.profit_r < -0.5).length
    },
    earlyLoss() {
      return this.trades.filter(x => -0.5 < x.profit_r && x.profit_r < 0).length
    },
    breakEven() {
      return this.trades.filter(x => 0 <= x.profit_r && x.profit_r < 0.5).length
    },
    winPercent() {
      return ( this.wins / (this.wins + this.losses) * 100 ).toFixed(2)
    },
    rProfit() {
      return this.trades.reduce((x, {profit_r}) => x + profit_r, 0).toFixed(2)
    },
    accountIncreasePercent() {
      return this.trades.reduce((x, {realized_percent}) => x + realized_percent, 0).toFixed(2)
    },
    profit() {
      return this.trades.reduce((x, {profit}) => x + profit, 0).toFixed(2)
    },
    accountBalance() {
      if (this.trades.length) {
        return this.trades[this.trades.length - 1].balance
      } else {
        return 0
      }
    }
  },
  methods: {
  }
}
</script>
